<template>
  <v-expansion-panel :disabled="disabled">
    <v-expansion-panel-header v-if="loading">
      <slot name="title-loading">
        Loading {{ typeLower }} information...
      </slot>
    </v-expansion-panel-header>
    <v-expansion-panel-header v-else>
      <slot name="title">
        {{ type }}
      </slot>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row
        v-if="!disableToolbar"
        dense
      >
        <v-col class="mx-0 mb-4" cols="12">
          <HelpText
            hide-title
            margin-x="0"
            margin-b="4"
            :page="helpPage"
          />
          <v-toolbar
            class="mb-4 my-0 py-0"
            dense
            flat
          >
            <v-progress-linear
              v-if="showProgressBar"
              :color="progressBarColor"
              indeterminate
              height="8"
              rounded
            ></v-progress-linear>
            <template v-else>
              <div :class="`${DEFAULT_TEXT} mr-2`" v-html="statusText"></div>
              <v-spacer></v-spacer>
              <template v-if="readonly">
                <tooltip-fab-icon-button
                  v-if="includeDelete"
                  @click="$emit('delete-data')"
                  bottom
                  class="mr-2"
                  :color="ERROR"
                  :disabled="disableDelete"
                  :icon="true"
                  :icon-name="DELETE_ICON"
                  text="Delete"
                />
                <tooltip-fab-icon-button
                  v-if="includeAdd"
                  @click="$emit('add-data')"
                  bottom
                  class="mr-2"
                  :color="SUCCESS"
                  :disabled="disableAdd"
                  :icon="true"
                  :icon-name="ADD_GENERIC_ICON"
                  text="Add"
                />
                <tooltip-fab-icon-button
                  v-if="!isEmpty"
                  @click="$emit('enable-edit')"
                  bottom
                  :color="PRIMARY"
                  :icon="true"
                  :icon-name="EDIT_ICON"
                  text="Edit"
                />
              </template>
              <template v-else>
                <tooltip-fab-icon-button
                  @click="$emit('save-data')"
                  bottom
                  class="mr-2"
                  :color="SUCCESS"
                  :disabled="disableSave"
                  :icon="true"
                  :icon-name="SAVE_ICON"
                  text="Save"
                />
                <tooltip-fab-icon-button
                  bottom
                  @click="$emit('cancel-edit')"
                  :color="SECONDARY"
                  :icon="true"
                  :icon-name="CANCEL_ICON"
                  text="Cancel"
                />
              </template>
            </template>
          </v-toolbar>
        </v-col>
      </v-row>
      <slot name="content"></slot>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { ICONS, TEXT_COLOR, COLOR, } from '../../constants'

export default {
  components: {
    HelpText: () => import('./HelpText.vue'),
    TooltipFabIconButton: () => import('../input/TooltipFabIconButton.vue'),
  },
  props: {
    deleting: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disableAdd: {
      type: Boolean,
      default: false,
    },
    disableDelete: {
      type: Boolean,
      default: false,
    },
    disableToolbar: {
      type: Boolean,
      default: false
    },
    disableSave: {
      type: Boolean,
      default: false,
    },
    includeAdd: {
      type: Boolean,
      default: false,
    },
    includeDelete: {
      type: Boolean,
      default: false,
    },
    helpPage: {
      type: String,
      default: '',
    },
    isEmpty: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: true,
    },
    saving: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
    },
  },
  data: () => ({
    typeLower: '',
  }),
  computed: {
    progressBarColor () {
      if (this.loading) {
        return COLOR.PRIMARY
      }
      else if (this.saving) {
        return COLOR.SUCCESS
      }
      else if (this.deleting) {
        return COLOR.ERROR
      }
    },
    showProgressBar() {
      return this.loading || this.saving || this.deleting
    },
    statusText () {
      if (this.readonly) {
        return `Data is <span class="${TEXT_COLOR.INFO}">read-only</span>`
      }
      else {
        return `Data is <span class="${TEXT_COLOR.INFO}">editable</span>`
      }
    },
  },
  mounted () {
    this.typeLower = this.type.toLowerCase()
  },
  created () {
    this.ADD_GENERIC_ICON = ICONS.ADD_GENERIC
    this.CANCEL_ICON = ICONS.CANCEL
    this.DELETE_ICON = ICONS.DELETE
    this.EDIT_ICON = ICONS.EDIT
    this.SAVE_ICON = ICONS.SAVE

    this.DEFAULT_TEXT = TEXT_COLOR.DEFAULT

    this.ERROR = COLOR.ERROR
    this.PRIMARY = COLOR.PRIMARY
    this.SECONDARY = COLOR.SECONDARY
    this.SUCCESS = COLOR.SUCCESS
  }
}
</script>

<style scoped>
  .v-toolbar >>> .v-toolbar__content {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
</style>